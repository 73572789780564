const english = {
  heading: "STRIVE",
  subHeading: "Platform",
  logoSideText:
    "Ministry of Village, Development of Disadvantaged Regions & Transmigration",
  loginDescription: 'Part of the Social Innovation Platform to listen more deeply to the aspirations of the community for the progress of the village.',
};
const bahasa = {
  heading: "STRIVE",
  subHeading: "Platform",
  logoSideText:
    "Kementerian Desa, Pembangunan Daerah Tertinggal, dan Transmigrasi Republik Indonesia",
  loginDescription: 'Bagian dari Social Innovation Platform untuk mendengar lebih dalam aspirasi masyarakat demi kemajuan desa.',
};

export { english, bahasa };
